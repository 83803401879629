@import url(https://fonts.googleapis.com/css2?family=Quicksand&display=swap);
/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
  min-height: 100vh;
  overflow-x: hidden;
  overflow-y: hidden;
}

.navbox {
  position: fixed;
  display: flex;
  background-color: transparent;
  /* top: 0px; */
  /* left: 0; */
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
  z-index: 1000;
}
/* .navbar:focus-within {
	visibility: visible;
} */

.ham {
  visibility: hidden;
  position: fixed;
  top: 10px;
  left: 10px;
  width: 2.5em;
  -webkit-filter: invert(1);
          filter: invert(1);
  z-index: 1001;
}

.navbox {
  padding-top: 30px;
}

.navbar .na {
  width: 100px;
  height: 25px;
  padding: 0.3em 1em;
  font-weight: 700;
  color: #ffff;
  border-radius: 20px;
  text-decoration: none;

  transition: background-color 0.2s, color 0.2s;
}
.navbar .na:hover,
.navbar .na.active {
  background-color: #ffff;
  color: #466ba3;
}

@media screen and (max-width: 600px) {
  .hidden {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  .navbox {
    flex-direction: column;
    width: 100%;
    /* height: fit-content; */
    top: 0px;
    padding: 30px 0px;
    background-color: rgba(0, 70, 87, 0.719);
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s;
  }
  .ham {
    visibility: visible;
  }
}

@media screen and (min-width: 600px) {
  .navbar {
    visibility: visible;
  }
}

.home-section {
  background: linear-gradient(#7597de, #eeee);
  position: relative;
  width: 100%;
  height: 100vh;
  padding-bottom: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.home-section img {
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
}
.home-section img#anti {
  align-items: center;
  margin-left: 25%;
  margin-top: 10%;
  width: 50%;
  height: 50%;
  z-index: 100;
}
.home-section #btn {
  text-decoration: none;
  display: inline-block;
  padding: 8px 30px;
  text-align: center;
  right: 20px;
  background: #eeee;
  border-radius: 40px;
  color: rgba(21, 38, 112, 0.658);
  font-size: 1.5em;
  z-index: 10000;
  -webkit-transform: translateY(250px);
          transform: translateY(250px);
}
.home-section #btn:hover,
.home-section .active {
  background-color: #ffff;
  color: #466ba3;
  opacity: 70%;
}
@media screen and (max-width: 600px) {
  .home-section img#anti {
    margin-left: 15%;
    display: flex;
    width: 70%;
    height: 50%;
    object-fit: cover;
  }
}

.skill-section {
  height: 100%;
  width: 100vw;
  align-items: center;
  background: linear-gradient(#7597de, #eeee);
  /* padding: 70px; */
}
.skill-section .codecard {
  display: inline-block;
  margin: 10px 10px 10px 10px;
}
.skill-section h1 {
  margin: 0rem;
  padding: 1em;
  font-size: 3rem;
}

.milestone-section {
  height: 100%;
  width: 100vw;
  background: linear-gradient(#7597de, #eeee);
}
.milestone-section h1 {
  margin-top: 0%;
  padding: 50px;
  font-size: 3rem;
}
.milestone-section h2 {
  margin: 0%;
  font-size: 1.3rem;
}
.milestone-section h3 {
  margin: 0%;
  font-size: 1rem;
}

.milestone-section p {
  margin: 1px;
  font-size: 1rem;
}

.about-section {
  height: 100%;
  width: 100vw;
  background-color: transparent;
}
.about-section h1 {
  margin-top: 0%;
  padding: 50px;
}
.about-section .section {
  position: relative;
  padding: 0%;
  margin: 0%;
  height: 100vh;
  width: 100vw;
}
.about-section .Background {
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
  opacity: 0.8;
}
.about-section #text {
  text-decoration: none;
  display: felx;
  padding: 8px 30px;
  text-align: center;
  right: 20px;
  color: rgb(255, 255, 255);
  font-size: 3em;
  z-index: 10;
  -webkit-transform: translateY(250px);
          transform: translateY(250px);
}
.about-section #text2 {
  text-decoration: none;
  display: felx;
  padding: 8px 30px;
  text-align: center;
  right: 20px;
  color: rgb(255, 255, 255);
  font-size: 2em;
  z-index: 10;
  -webkit-transform: translateY(250px);
          transform: translateY(250px);
}

.about-section #btn {
  text-decoration: none;
  display: inline-block;
  padding: 8px 30px;
  text-align: center;
  right: 20px;
  background: #eeee;
  border-radius: 40px;
  color: rgba(21, 38, 112, 0.658);
  font-size: 3em;
  z-index: 10000;
  -webkit-transform: translateY(250px);
          transform: translateY(250px);
}

.contact-section {
	display: flex;
	justify-content: space-between;
	align-items: center;

	width: 100%;
	min-height: 20vh;
	background-color: #000000;
	align-items: center;
}
.contact-section .contact-copyright p {
	text-align: left;
	margin-left: 30px;
	/* position: absolute; */
	color: #eeee;
}
/* .contact-section .contact-icon {
	background-color: blue;
} */
.contact-section .contact-icon .fa {
	border-radius: 25%;
	margin: 5px;
	padding: 10px;
	font-size: 35px;
	color: white;
	text-decoration: none;

	transition: background-color 0.2s, color 0.2s;
}

.contact-section .contact-icon .fa-github:hover {
	background: #555555;
	color: white;
}
.contact-section .contact-icon .fa-twitter:hover {
	background: #55acee;
	color: white;
}
.contact-section .contact-icon .fa-google:hover {
	background: #dd4b39;
	color: white;
}
.contact-section .contact-icon .fa-instagram:hover {
	background: #125688;
	color: white;
}
.contact-section .contact-icon .fa-phone-square:hover {
	background: #52b35a;
	color: white;
}
.contact-section .contact-icon .fa-stack-overflow:hover {
	background: #f1f1f1;
	color: rgb(240, 164, 64);
}
.contact-section .contact-icon .fa-discord:hover {
	background: #4c69eb;
	color: rgb(218, 218, 218);
}

@media screen and (max-width: 600px) {
	.contact-section {
		flex-direction: column;
	}
	.contact-section .contact-copyright p {
		text-align: center;
		margin-left: 0px;
	}
}

.project-section {
  height: 100%;
  width: 100vw;
  align-items: center;
  background: linear-gradient(#7597de, #eeee);
  /* padding: 70px; */
}
.project-section h1 {
  margin: 0%;

  font-size: 5em;
}
.project-section p {
  font-size: 1.5em;
}

.work {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: aliceblue;
}

.work .background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
  z-index: -1;
  opacity: 0.9;
}
.work .header {
  margin: 0%;
  padding: 2%;
  font-size: 4rem;
  text-align: center;
}
.work .content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 75%;
  height: 100%;
  background-color: rgb(43, 67, 90);
  padding: 1rem;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  -webkit-backdrop-filter: opacity(99%);
          backdrop-filter: opacity(99%);
  text-align: justify;
  /* opacity: 0.9; */
}
.work #icon {
  color: aliceblue;
}
.work img {
  width: 80%;
  height: auto;
}
.work code {
  background-color: rgba(200, 200, 200, 0.609);
  border-radius: 15%;
}

.page404 {
  height: 80vh;
  width: 100%;
  background: linear-gradient(#7597de, #eeee);
}
.page404 h1 {
  margin-top: 0%;
  font-size: 100px;
  padding-top: 100px;
}

