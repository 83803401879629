.work {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: aliceblue;
}

.work .background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
  z-index: -1;
  opacity: 0.9;
}
.work .header {
  margin: 0%;
  padding: 2%;
  font-size: 4rem;
  text-align: center;
}
.work .content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 75%;
  height: 100%;
  background-color: rgb(43, 67, 90);
  padding: 1rem;
  backdrop-filter: blur(5px);
  backdrop-filter: opacity(99%);
  text-align: justify;
  /* opacity: 0.9; */
}
.work #icon {
  color: aliceblue;
}
.work img {
  width: 80%;
  height: auto;
}
.work code {
  background-color: rgba(200, 200, 200, 0.609);
  border-radius: 15%;
}
