.contact-section {
	display: flex;
	justify-content: space-between;
	align-items: center;

	width: 100%;
	min-height: 20vh;
	background-color: #000000;
	align-items: center;
}
.contact-section .contact-copyright p {
	text-align: left;
	margin-left: 30px;
	/* position: absolute; */
	color: #eeee;
}
/* .contact-section .contact-icon {
	background-color: blue;
} */
.contact-section .contact-icon .fa {
	border-radius: 25%;
	margin: 5px;
	padding: 10px;
	font-size: 35px;
	color: white;
	text-decoration: none;

	transition: background-color 0.2s, color 0.2s;
}

.contact-section .contact-icon .fa-github:hover {
	background: #555555;
	color: white;
}
.contact-section .contact-icon .fa-twitter:hover {
	background: #55acee;
	color: white;
}
.contact-section .contact-icon .fa-google:hover {
	background: #dd4b39;
	color: white;
}
.contact-section .contact-icon .fa-instagram:hover {
	background: #125688;
	color: white;
}
.contact-section .contact-icon .fa-phone-square:hover {
	background: #52b35a;
	color: white;
}
.contact-section .contact-icon .fa-stack-overflow:hover {
	background: #f1f1f1;
	color: rgb(240, 164, 64);
}
.contact-section .contact-icon .fa-discord:hover {
	background: #4c69eb;
	color: rgb(218, 218, 218);
}

@media screen and (max-width: 600px) {
	.contact-section {
		flex-direction: column;
	}
	.contact-section .contact-copyright p {
		text-align: center;
		margin-left: 0px;
	}
}
