.skill-section {
  height: 100%;
  width: 100vw;
  align-items: center;
  background: linear-gradient(#7597de, #eeee);
  /* padding: 70px; */
}
.skill-section .codecard {
  display: inline-block;
  margin: 10px 10px 10px 10px;
}
.skill-section h1 {
  margin: 0rem;
  padding: 1em;
  font-size: 3rem;
}
