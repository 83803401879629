.milestone-section {
  height: 100%;
  width: 100vw;
  background: linear-gradient(#7597de, #eeee);
}
.milestone-section h1 {
  margin-top: 0%;
  padding: 50px;
  font-size: 3rem;
}
.milestone-section h2 {
  margin: 0%;
  font-size: 1.3rem;
}
.milestone-section h3 {
  margin: 0%;
  font-size: 1rem;
}

.milestone-section p {
  margin: 1px;
  font-size: 1rem;
}
