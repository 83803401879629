.about-section {
  height: 100%;
  width: 100vw;
  background-color: transparent;
}
.about-section h1 {
  margin-top: 0%;
  padding: 50px;
}
.about-section .section {
  position: relative;
  padding: 0%;
  margin: 0%;
  height: 100vh;
  width: 100vw;
}
.about-section .Background {
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
  opacity: 0.8;
}
.about-section #text {
  text-decoration: none;
  display: felx;
  padding: 8px 30px;
  text-align: center;
  right: 20px;
  color: rgb(255, 255, 255);
  font-size: 3em;
  z-index: 10;
  transform: translateY(250px);
}
.about-section #text2 {
  text-decoration: none;
  display: felx;
  padding: 8px 30px;
  text-align: center;
  right: 20px;
  color: rgb(255, 255, 255);
  font-size: 2em;
  z-index: 10;
  transform: translateY(250px);
}

.about-section #btn {
  text-decoration: none;
  display: inline-block;
  padding: 8px 30px;
  text-align: center;
  right: 20px;
  background: #eeee;
  border-radius: 40px;
  color: rgba(21, 38, 112, 0.658);
  font-size: 3em;
  z-index: 10000;
  transform: translateY(250px);
}
