.home-section {
  background: linear-gradient(#7597de, #eeee);
  position: relative;
  width: 100%;
  height: 100vh;
  padding-bottom: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.home-section img {
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
}
.home-section img#anti {
  align-items: center;
  margin-left: 25%;
  margin-top: 10%;
  width: 50%;
  height: 50%;
  z-index: 100;
}
.home-section #btn {
  text-decoration: none;
  display: inline-block;
  padding: 8px 30px;
  text-align: center;
  right: 20px;
  background: #eeee;
  border-radius: 40px;
  color: rgba(21, 38, 112, 0.658);
  font-size: 1.5em;
  z-index: 10000;
  transform: translateY(250px);
}
.home-section #btn:hover,
.home-section .active {
  background-color: #ffff;
  color: #466ba3;
  opacity: 70%;
}
@media screen and (max-width: 600px) {
  .home-section img#anti {
    margin-left: 15%;
    display: flex;
    width: 70%;
    height: 50%;
    object-fit: cover;
  }
}
