.page404 {
  height: 80vh;
  width: 100%;
  background: linear-gradient(#7597de, #eeee);
}
.page404 h1 {
  margin-top: 0%;
  font-size: 100px;
  padding-top: 100px;
}
