.navbox {
  position: fixed;
  display: flex;
  background-color: transparent;
  /* top: 0px; */
  /* left: 0; */
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
  z-index: 1000;
}
/* .navbar:focus-within {
	visibility: visible;
} */

.ham {
  visibility: hidden;
  position: fixed;
  top: 10px;
  left: 10px;
  width: 2.5em;
  filter: invert(1);
  z-index: 1001;
}

.navbox {
  padding-top: 30px;
}

.navbar .na {
  width: 100px;
  height: 25px;
  padding: 0.3em 1em;
  font-weight: 700;
  color: #ffff;
  border-radius: 20px;
  text-decoration: none;

  transition: background-color 0.2s, color 0.2s;
}
.navbar .na:hover,
.navbar .na.active {
  background-color: #ffff;
  color: #466ba3;
}

@media screen and (max-width: 600px) {
  .hidden {
    transform: translateY(-100%);
  }
  .navbox {
    flex-direction: column;
    width: 100%;
    /* height: fit-content; */
    top: 0px;
    padding: 30px 0px;
    background-color: rgba(0, 70, 87, 0.719);
    backdrop-filter: blur(5px);
    transition: transform 0.3s;
  }
  .ham {
    visibility: visible;
  }
}

@media screen and (min-width: 600px) {
  .navbar {
    visibility: visible;
  }
}
